import { getCountryCodeName } from '@guider-global/front-end-utils';
import { IChartsSessions } from '@guider-global/shared-types';
import {
  IMultiSelectChipFilterOption,
  getCustomGridAutocompleteFilterOperator,
  getCustomGridDateFilterOperator,
} from '@guider-global/ui';
import { GridColDef } from '@mui/x-data-grid';
import { format, formatDuration } from 'date-fns';

export interface IGetGroupSessionColumnsParams {
  guideOrganizationLabel?: string;
  traineePluralized?: string;
  countryLabel?: string;
  countryOptions: IMultiSelectChipFilterOption[];
  countryEnabled?: boolean;
}

type SessionGridColumn<Value = any, FormattedValue = Value> = GridColDef<
  IChartsSessions,
  Value,
  FormattedValue
>;

export const getGroupSessionColumns = ({
  guideOrganizationLabel = 'guide',
  traineePluralized = 'trainee',
  countryLabel = 'Country',
  countryOptions,
  countryEnabled,
}: IGetGroupSessionColumnsParams) => {
  const getCountryColumn = () => {
    if (countryEnabled) {
      const country: SessionGridColumn<string, string> = {
        field: 'guideCountry',
        headerName: countryLabel,
        valueFormatter: ({ value }) => getCountryCodeName(value) ?? value,
        minWidth: 200,
        filterOperators: [
          getCustomGridAutocompleteFilterOperator({
            componentProps: {
              options: countryOptions,
            },
          }),
        ],
      };
      return [country];
    }
    return [];
  };
  const columns: SessionGridColumn[] = [
    {
      field: 'sessionStart',
      headerName: 'Sessions start',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
      filterOperators: [getCustomGridDateFilterOperator({})],
    },
    {
      field: 'sessionEnd',
      headerName: 'Session end',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
    },
    { field: 'name', headerName: 'Name', minWidth: 200 },
    {
      field: 'duration',
      headerName: 'Duration',
      type: 'number',
      valueFormatter: ({ value }) => {
        return formatDuration({ minutes: value });
      },
    },
    {
      field: 'totalMentoringTime',
      headerName: 'Total mentoring time',
      type: 'number',
      valueGetter: ({ row }) => (row.duration ?? 0) * (row.traineeCount ?? 0),
      valueFormatter: ({ value }) => `${value} minutes`,
      minWidth: 150,
    },
    {
      field: 'guideFirstName',
      headerName: `${guideOrganizationLabel} First Name`,
      minWidth: 130,
    },
    {
      field: 'guideLastName',
      headerName: `${guideOrganizationLabel} Last Name`,
      minWidth: 130,
    },
    {
      field: 'guideEmail',
      headerName: `${guideOrganizationLabel} Email`,
      minWidth: 200,
    },
    ...getCountryColumn(),
    {
      field: 'traineeCount',
      headerName: `${traineePluralized} Invited`,
      minWidth: 130,
      type: 'number',
    },
  ];
  return columns;
};
